<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar>
        <ion-buttons slot="start" @click="backToHome" class="text-primary">
          <span class="font-15 ms-1"><i class="fa-solid fa-chevron-left me-2"></i>ย้อนกลับ</span>
        </ion-buttons>
        <ion-title>ช่องทางชำระเงิน</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" :fullscreen="true">
      <div class="content mt-4 color-black">
        <div class="row">
          <div class="col-12 text-center">
            <div class="card card-style">
              <div class="content">
                <div v-if="order.status === 3 || order.status === 4">
                  <h5 class="text-danger"><i class="fa fa-fw fa-clock fa-3x"></i></h5>
                  <h5 class="text-danger">หมดเวลาในอีก</h5>
                  <h1 class="text-danger">{{ timer.min }}:{{ timer.sec }}</h1>
                  <div class="divider mt-4 mb-4"></div>
                </div>
                <div v-else-if="order.status === 1">
                  <h5 class="text-success"><i class="fa fa-fw fa-check-circle fa-3x"></i></h5>
                  <h1 class="text-success">ชำระเงินแล้ว</h1>
                  <div class="divider mt-4 mb-4"></div>
                </div>
                <div v-else-if="order.status === 0">
                  <h5 class="text-danger"><i class="fa fa-fw fa-times-circle fa-3x"></i></h5>
                  <h1 class="text-danger">คำสั่งซื้อถูกยกเลิก</h1>
                  <div class="divider mt-4 mb-4"></div>
                </div>
                <div class="d-flex mb-2" v-for="lottery in order.lotteries" :key="lottery.full_number">
                  <div class="ion-text-start">
                    <h5 class="font-500"><span class="badge bg-primary rounded-3 me-2">{{
                        lottery.count
                      }} ใบ</span>{{ lottery.full_number }} <i class="fa fa-image"
                                                               @click="openImageViewer(lottery.image)"></i></h5>
                  </div>
                  <div class="ms-auto"><h5>{{ numberformat(lottery.price) }} บาท</h5></div>
                </div>
                <div class="d-flex">
                  <div><h4 class="font-700">ยอดชำระรวม</h4></div>
                  <div class="ms-auto"><h3 class="text-success">{{ numberformat(order?.total) }} บาท</h3></div>
                </div>
                <div class="divider mb-2 mt-2"></div>
                <div class="row mx-auto mt-3 mb-3 text-center" style="max-width:320px;" v-if="order.status === 3">
                  <div class="col" v-if="payments?.mmn_payment || payments?.deePay">
                    <button @click="openModal('qr')" class="icon icon-xxl bg-yellow-dark rounded-3 mb-1">
                      <span class="badge bg-green-dark position-absolute float rounded-2" style="right: 0">แนะนำ</span>
                      <i class="fa fa-qrcode"></i>
                    </button>
                    <p>QR Code</p>
                  </div>
                  <div class="col" v-if="payments?.mmn_payment === null && payments?.bank?.bank_deposit_number">
                    <button @click="openModal('bank')" class="icon icon-xxl bg-blue-dark rounded-3 mb-1"><i
                        class="fa fa-bank"></i></button>
                    <p>บัญชีธนาคาร</p>
                  </div>
                  <div class="col">
                    <a ref="oa" :href="contact.support" target="_blank"
                       class="icon icon-xxl bg-green-light rounded-3 mb-1"><i
                        class="fa fa-headphones"></i></a>
                    <p>แจ้งปัญหา</p>
                  </div>
                </div>
                <div class="row mx-auto mt-3 mb-3 text-center" v-else-if="order.status === 1">
                  <div class="col">
                    <router-link to="/myLottery" class="icon icon-xxl bg-green-light rounded-3 mb-1"><i
                        class="fa fa-ticket"></i></router-link>
                    <p>ไปที่สลากของฉัน</p>
                  </div>
                </div>
                <div class="row mx-auto mt-3 mb-3 text-center" v-else>
                  <div class="col">
                    <router-link to="/home" class="icon icon-xxl bg-btn-default rounded-3 mb-1"><i
                        class="fa fa-store"></i></router-link>
                    <p>ไปที่หน้าแรก</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            <p class="mb-0">การชำระเงินทุกรูปแบบ ใช้การตรวจสอบไม่เกิน 2 นาที</p>
            <p>หากชำระเงินแล้วเกิน 2 นาทีระบบยังไม่อนุมัติ กรุณาติดต่อฝ่ายบริการลูกค้า</p>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  alertController,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  modalController,
} from '@ionic/vue';
import QRCode from "@/views/payment/QRCode";
import Bank from "@/views/payment/Bank";
import ImageViewer from "@/components/ImageViewer";

export default {
  name: 'PaymentIndex',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
  },
  ionViewWillEnter() {
    this.order_id = this.$route.query?.id;
    this.getData().then(() => {
      this.timerCD();
      this.loop_cd = setInterval(() => {
        this.timerCD();
      }, 1000)
      this.loop_check = setInterval(() => {
        this.getData();
      }, 5000)

      this.openModal('qr');
    });
  },
  data() {
    return {
      order_id: null,
      order: [],
      payments: [],
      exp: null,
      timer: {
        sec: "00",
        min: "00",
        hour: "00",
        day: "00"
      },
      loop_cd: null,
      loop_check: null,
      imageViewer: {
        show: false,
        images: []
      },
      contact: []
    }
  },
  ionViewWillLeave() {
    clearInterval(this.loop_cd);
    clearInterval(this.loop_check);
  },
  mounted() {
    this.order_id = this.$route.query?.id;
    this.getData();
    this.getContact();
  },
  methods: {
    async backToHome() {
      await this.$router.push('/home')
      this.emitter.emit('back-home');
    },
    numberformat(x) {
      try {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch (e) {
        return 0
      }
    },
    timerCD() {
      const now = this.moment(new Date()); //todays date
      const end = this.moment(this.exp); // another date
      const duration = this.moment.duration(now.diff(end));

      if (duration.days() >= 0 && duration.hours() >= 0 && duration.minutes() >= 0 && duration.seconds() >= 0) {
        this.timer = {
          sec: "00",
          min: "00",
          hour: "00",
          day: "00"
        };

        this.cd = false;
        clearInterval(this.loop_cd);
        // setTimeout(() => {
        //   this.$router.push("/store");
        // }, 2000)
      } else {
        this.timer.sec = Math.abs(duration.seconds()) < 10 ? `0${Math.abs(duration.seconds())}` : Math.abs(duration.seconds());
        this.timer.min = Math.abs(duration.minutes()) < 10 ? `0${Math.abs(duration.minutes())}` : Math.abs(duration.minutes());
        this.timer.hour = Math.abs(duration.hours()) < 10 ? `0${Math.abs(duration.hours())}` : Math.abs(duration.hours());
        this.timer.day = Math.abs(duration.days()) < 10 ? `0${Math.abs(duration.days())}` : Math.abs(duration.days());
      }
    },
    async getData() {
      try {
        const response = await this.axios.post(`/order`, {
          order_id: this.order_id
        })

        if (response.data.code === 0) {
          this.order = response.data.result;
          this.payments = response.data.payments;
          this.exp = response.data.exp;

          if (this.order.status !== 3) {
            clearInterval(this.loop_check);
            clearInterval(this.loop_cd);
          }
        } else {
          const alert = await alertController.create({
            header: 'ขออภัย',
            subHeader: 'ไม่พบคำสั่งซื้อที่ท่านค้นหา',
            buttons: ['ปิด'],
            mode: "ios"
          });

          await alert.present();
          this.$router.push('/home');
        }
      } catch (error) {
        const alert = await alertController.create({
          header: 'ขออภัย',
          subHeader: 'ไม่พบคำสั่งซื้อที่ท่านค้นหา',
          buttons: ['ปิด'],
          mode: "ios"
        });

        await alert.present();
        this.$router.push('/home');
      }
    },
    async openModal(type) {
      const modal = await modalController.create({
        component: type === 'qr' ? QRCode : Bank,
        componentProps: {
          payments: this.payments,
          order: this.order
        },
        initialBreakpoint: 1,
        breakpoints: [0, 1],
        mode: 'ios',
      });
      await modal.present();

      const {data, role} = await modal.onDidDismiss();

      if (role === 'oa') {
        window.location.href = this.contact.support;
      }
    },
    async openImageViewer(images) {
      this.imageViewer.images = images;
      this.imageViewer.show = true;
      console.log(this.imageViewer)
      const modal = await modalController.create({
        component: ImageViewer,
        componentProps: {
          images: images,
          title: "รูปสลาก"
        },
        initialBreakpoint: 1,
      });

      await modal.present();

      const {data, role} = await modal.onWillDismiss();
    },
    async getContact() {
      const response = await this.axios.get(`/profile`);
      this.contact = response.data.result;
    }
  },
}
</script>
<style scoped>
.block {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-modal {
  --height: auto;
}

ion-backdrop {
  background: var(--ion-color-dark);
  opacity: 0.3;
}

.backdrop-hide {
  background: transparent !important;
  z-index: 0;
}
</style>
